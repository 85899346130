<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="is-pulled-right">
            <b-field grouped>
              <b-button type="is-primary"
                icon-left="download"
                :disabled="disabled"
                :loading="loading"
                @click="load()">
                Jooksuta
              </b-button>
            </b-field>
          </div>
          <h1 class="title">
            Leiulogide võrdlus
          </h1>
          <h2 class="subtitle">
            GP / GC Eesti aardelogide erinevused
          </h2>
        </div>
      </div>
    </section>
    <section v-if="stats.length">
      <div class="container">
        <section class="modal-card-body">
          <b-table class="my-3"
            :data="stats"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            :mobile-cards="true">
            <b-table-column field="id" label="" v-slot="props">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="gp" label="geopeitus.ee" v-slot="props">
              {{ props.row['gp'] }}
            </b-table-column>
            <b-table-column field="gc" label="geocaching.com" v-slot="props">
              {{ props.row['gc'] }}
            </b-table-column>
          </b-table>
        </section>
      </div>
    </section>
  </div>
</template>

<script>

import apiService from '@/services/ApiService'
import { error } from '@/utils'

export default {
  name: 'CachesStatistics',
  data() {
    let data = JSON.parse(localStorage.getItem('user'))
    const providers = []
    if (data && data['gp']) {
      providers.push('gp')
    }
    if (data && data['gc']) {
      providers.push('gc')
    }
    return {
      providers: providers,
      disabled: providers.length === 0,
      loading: false,
      stats: [],
      statsList: {
        total: 'Logide arv',
        totalDiff: 'Logide koguarvu erinevus',
        totalCommon: 'Kokkulangevaid logisid',
        missedCount: 'Puuduvate logide arv',
        commonFalsesCount: 'Vigaseid kokkulangevaid logisid'
      }
    }
  },
  methods: {
    load () {
      this.disabled = true
      this.loading = true

      apiService.loadLogsStatistics()
        .then(response => {
          if (response.ok) {
            const data = []
            Object.keys(response.data).forEach(key => {
              if (key in this.statsList) {
                data.push(this.formatRow(key, response.data[key]))
              } else if (key === 'compare') {
                const comparison = {}
                Object.keys(response.data[key]).forEach(provider => {
                  Object.keys(response.data[key][provider].to).forEach(to => {
                    Object.keys(response.data[key][provider].to[to]).forEach(key2 => {
                      if (!(key2 in comparison)) {
                        comparison[key2] = {}
                      }
                      if (!(provider in comparison[key2])) {
                        comparison[key2][provider] = response.data[key][provider].to[to][key2]
                      }
                    })
                  })
                })
                Object.keys(comparison).forEach(key2 => {
                  if (key2 in this.statsList) {
                    data.push(this.formatRow(key2, comparison[key2]))
                  }
                })
              }
            })
            this.stats = data
          }
        })
        .catch(error)
        .finally(() => {
          this.disabled = false
          this.loading = false
        })
    },
    formatRow(key, data) {
      return Object.assign({}, data, { id: this.statsList[key] })
    }
  }
}
</script>
